<template>
  <v-container class="pa-0 pb-16">
    <v-row justify="center" class="mt-2 mb-2">
      <div class="text-h5 font-weight-bold grey--text text--darken-4">
        {{ $t("choosePlan") }}
      </div>
    </v-row>
    <v-row justify="center">
      <v-hover
        v-for="(subscription, index) in subscriptionData"
        :key="index"
        v-slot="{ hover }"
      >
        <v-card
          v-model="selectedSubscription"
          :elevation="hover ? 15 : 0"
          :width="$vuetify.breakpoint.mdAndUp ? '80%' : '85%'"
          :height="'100%'"
          rounded
          outlined
          class="text-none rounded-lg mt-5"
        >
          <v-row no-gutters>
            <v-col
              style="background: #f4f8ff"
              cols="12"
              lg="3"
              md="4"
              xl="3"
              sm="12"
              class="pa-0"
            >
              <v-card-title v-if="$vuetify.breakpoint.mdAndUp">
                <div
                  class="primary--text font-weight-bold h6"
                  :class="$vuetify.breakpoint.mdAndUp ? 'mx-auto' : ''"
                >
                  {{ subscription.name }}
                </div>
              </v-card-title>
              <v-card-text v-if="$vuetify.breakpoint.mdAndUp" class="pa-0">
                <v-row v-if="subscription.name != 'Free'" no-gutters>
                  <span class="mx-auto font-weight-medium">{{
                    $t("userMaximum")
                  }}</span>
                </v-row>
                <v-row
                  v-if="subscription.name != 'Free'"
                  no-gutters
                  justify="center"
                  class="mb-3"
                >
                  <v-col cols="5">
                    <v-select
                      v-model="selectedNumberOfUsers"
                      :items="numberOfUsersList"
                      item-value="users"
                      item-text="users"
                      label=""
                      :menu-props="{ bottom: true, offsetY: true }"
                      dense
                      hide-details
                      outlined
                      return-object
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="!$vuetify.breakpoint.mdAndUp" class="pa-0">
                <v-row no-gutters justify="center" class="mb-3">
                  <v-col>
                    <v-card-title>
                      <div class="primary--text font-weight-bold h6">
                        {{ subscription.name }}
                      </div>
                    </v-card-title>
                  </v-col>
                  <v-col
                    v-if="subscription.name != 'Free'"
                    class="pb-4 pt-5 pl-4 text-right"
                  >
                    <span class="font-weight-medium">{{
                      $t("userMaximum")
                    }}</span>
                  </v-col>
                  <v-col
                    v-if="subscription.name != 'Free'"
                    class="pb-4 pt-3 pl-4 pr-2"
                    cols="4"
                  >
                    <v-select
                      v-model="selectedNumberOfUsers"
                      :items="numberOfUsersList"
                      item-value="users"
                      item-text="users"
                      label=""
                      :menu-props="{ bottom: true, offsetY: true }"
                      dense
                      hide-details
                      outlined
                      return-object
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="pa-0">
                <v-row
                  v-if="
                    !(
                      selectedNumberOfUsers.users === '10+' &&
                      subscription.name === 'Pro'
                    )
                  "
                  no-gutters
                  class="mx-auto"
                >
                  <v-col
                    cols="6"
                    class="text-right pr-3 mx-auto grey-darken-4--text font-weight-bold text-h4"
                    >${{ subscription.name !== "Free" ? getPrice : "0" }}</v-col
                  >
                  <v-col cols="6">
                    <v-row no-gutters>
                      <span
                        class="font-weight-regular caption grey-darken-2--text"
                      >
                        {{ $t("perUser") }}
                      </span>
                    </v-row>
                    <v-row no-gutters>
                      <span
                        class="grey-darken-2--text font-weight-regular caption"
                      >
                        {{ $t("perMonth") }}
                      </span>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                  v-if="subscription.name !== 'Free'"
                  no-gutters
                  class="mt-3"
                  justify="center"
                >
                  <v-btn
                    height="auto"
                    class="ma-1 px-1 py-0 text-none rounded-lg"
                    style="font-size: 10px"
                    outlined
                    color="#0073D1"
                    ><span>{{ $t("billedQuarterlyAnnually") }}</span>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-col cols="12">
                  <v-btn
                    block
                    color="primary"
                    class="text-none"
                    @click="submit(subscription)"
                  >
                    <span class="font-weight-regular subtitle">
                      {{ $t("selectPlan") }}
                    </span>
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-col>
            <v-col cols="12" lg="9" md="8" xl="9" sm="12" class="pa-0">
              <v-row
                v-if="subscription.name === 'Free'"
                justify="center"
                class="my-6"
                no-gutters
                :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-1'"
              >
                <v-col
                  v-for="(item, i) in subscription.options"
                  :key="i"
                  cols="12"
                  :lg="i > 5 ? '10' : '5'"
                  md="10"
                  :xl="i > 5 ? '10' : '5'"
                  sm="12"
                  class="pa-0"
                  :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-8'"
                >
                  <v-icon
                    dense
                    :color="item.extraFeature ? '#1E853A' : '#E0E0E0'"
                    v-text="'mdi-check'"
                  ></v-icon>

                  <span
                    class="ml-2 text-body-2"
                    :class="
                      item.extraFeature && subscription.name !== 'Free'
                        ? 'font-weight-bold'
                        : null
                    "
                    v-text="item.name"
                  ></span>
                </v-col>
              </v-row>
              <v-row
                v-else
                justify="center"
                class="my-6"
                no-gutters
                :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-1'"
              >
                <v-col
                  v-if="subscription.name !== 'Free'"
                  cols="12"
                  lg="5"
                  md="10"
                  xl="5"
                  sm="12"
                  class="pa-0"
                  :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-8'"
                >
                  <v-row
                    v-for="(item, i) in subscription.nonExtraFeatureList"
                    :key="i"
                    no-gutters
                    class="my-1"
                  >
                    <v-icon
                      dense
                      :color="item.extraFeature ? '#1E853A' : '#E0E0E0'"
                      v-text="'mdi-check'"
                    ></v-icon>

                    <span
                      class="ml-2 text-body-2"
                      :class="
                        item.extraFeature && subscription.name !== 'Free'
                          ? 'font-weight-bold'
                          : null
                      "
                      v-text="item.name"
                    ></span>
                  </v-row>
                </v-col>
                <v-col
                  v-if="subscription.name !== 'Free'"
                  cols="12"
                  lg="5"
                  md="10"
                  xl="5"
                  sm="12"
                  class="pa-0"
                  :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-8'"
                >
                  <v-row
                    v-for="(item, i) in subscription.extraFeatureList"
                    :key="i"
                    no-gutters
                    class="my-1"
                  >
                    <v-icon
                      dense
                      :color="item.extraFeature ? '#1E853A' : '#E0E0E0'"
                      v-text="'mdi-check'"
                    ></v-icon>

                    <span
                      class="ml-2 text-body-2"
                      :class="
                        item.extraFeature && subscription.name !== 'Free'
                          ? 'font-weight-bold'
                          : null
                      "
                      v-text="item.name"
                    ></span>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CompanySubscription",
  props: {
    subscriptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    defaultSelectedUser: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      selectedSubscription: -1,
      selectedNumberOfUsers: {
        users: "2",
      },
    };
  },
  computed: {
    subscriptionData() {
      return this.subscriptions.filter((element) => {
        if (element.name === "Free" && !element.is_new) {
          return true;
        } else if (
          element.name === "Pro" &&
          element.users === "2" &&
          !element.is_new
        ) {
          return true;
        }
      });
    },
    numberOfUsersList() {
      return this.subscriptions.filter((element) => {
        if (element.name === "Pro") {
          return true;
        }
      });
    },
    getPrice() {
      if (this.selectedNumberOfUsers.users === "2") {
        return this.defaultSelectedUser ? this.defaultSelectedUser.price : "0";
      }
      return this.selectedNumberOfUsers.price
        ? this.selectedNumberOfUsers.price
        : "0";
    },
  },
  methods: {
    getFeatureCols(plan) {
      if (plan === "Free") return 5;
      else return 9;
    },
    selecteditems(index) {
      this.selectedSubscription = index;
    },
    async submit(selectedSubscription) {
      let subscriptionData = {};
      if (selectedSubscription.name === "Free") {
        subscriptionData = this.subscriptions[0];
      } else if (selectedSubscription.name === "Pro") {
        if (this.selectedNumberOfUsers.users === "2") {
          subscriptionData = this.subscriptions.find(
            (element) => element.users === "2" && element.name === "Pro",
          );
        } else {
          subscriptionData = this.selectedNumberOfUsers;
        }
      }
      this.$emit("updateStep", subscriptionData);
    },
  },
};
</script>

<style lang="scss"></style>
