var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 pb-16" },
    [
      _c("v-row", { staticClass: "mt-2 mb-2", attrs: { justify: "center" } }, [
        _c(
          "div",
          { staticClass: "text-h5 font-weight-bold grey--text text--darken-4" },
          [_vm._v(" " + _vm._s(_vm.$t("choosePlan")) + " ")]
        ),
      ]),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        _vm._l(_vm.subscriptionData, function (subscription, index) {
          return _c("v-hover", {
            key: index,
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var hover = ref.hover
                    return [
                      _c(
                        "v-card",
                        {
                          staticClass: "text-none rounded-lg mt-5",
                          attrs: {
                            elevation: hover ? 15 : 0,
                            width: _vm.$vuetify.breakpoint.mdAndUp
                              ? "80%"
                              : "85%",
                            height: "100%",
                            rounded: "",
                            outlined: "",
                          },
                          model: {
                            value: _vm.selectedSubscription,
                            callback: function ($$v) {
                              _vm.selectedSubscription = $$v
                            },
                            expression: "selectedSubscription",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: { background: "#f4f8ff" },
                                  attrs: {
                                    cols: "12",
                                    lg: "3",
                                    md: "4",
                                    xl: "3",
                                    sm: "12",
                                  },
                                },
                                [
                                  _vm.$vuetify.breakpoint.mdAndUp
                                    ? _c("v-card-title", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "primary--text font-weight-bold h6",
                                            class: _vm.$vuetify.breakpoint
                                              .mdAndUp
                                              ? "mx-auto"
                                              : "",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(subscription.name) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.$vuetify.breakpoint.mdAndUp
                                    ? _c(
                                        "v-card-text",
                                        { staticClass: "pa-0" },
                                        [
                                          subscription.name != "Free"
                                            ? _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "mx-auto font-weight-medium",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("userMaximum")
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          subscription.name != "Free"
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticClass: "mb-3",
                                                  attrs: {
                                                    "no-gutters": "",
                                                    justify: "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "5" } },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.numberOfUsersList,
                                                          "item-value": "users",
                                                          "item-text": "users",
                                                          label: "",
                                                          "menu-props": {
                                                            bottom: true,
                                                            offsetY: true,
                                                          },
                                                          dense: "",
                                                          "hide-details": "",
                                                          outlined: "",
                                                          "return-object": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedNumberOfUsers,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedNumberOfUsers =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedNumberOfUsers",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.$vuetify.breakpoint.mdAndUp
                                    ? _c(
                                        "v-card-text",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mb-3",
                                              attrs: {
                                                "no-gutters": "",
                                                justify: "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-card-title", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "primary--text font-weight-bold h6",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              subscription.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              subscription.name != "Free"
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pb-4 pt-5 pl-4 text-right",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight-medium",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "userMaximum"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              subscription.name != "Free"
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pb-4 pt-3 pl-4 pr-2",
                                                      attrs: { cols: "4" },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.numberOfUsersList,
                                                          "item-value": "users",
                                                          "item-text": "users",
                                                          label: "",
                                                          "menu-props": {
                                                            bottom: true,
                                                            offsetY: true,
                                                          },
                                                          dense: "",
                                                          "hide-details": "",
                                                          outlined: "",
                                                          "return-object": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedNumberOfUsers,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedNumberOfUsers =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedNumberOfUsers",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0" },
                                    [
                                      !(
                                        _vm.selectedNumberOfUsers.users ===
                                          "10+" && subscription.name === "Pro"
                                      )
                                        ? _c(
                                            "v-row",
                                            {
                                              staticClass: "mx-auto",
                                              attrs: { "no-gutters": "" },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "text-right pr-3 mx-auto grey-darken-4--text font-weight-bold text-h4",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _vm._v(
                                                    "$" +
                                                      _vm._s(
                                                        subscription.name !==
                                                          "Free"
                                                          ? _vm.getPrice
                                                          : "0"
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight-regular caption grey-darken-2--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "perUser"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "grey-darken-2--text font-weight-regular caption",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "perMonth"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      subscription.name !== "Free"
                                        ? _c(
                                            "v-row",
                                            {
                                              staticClass: "mt-3",
                                              attrs: {
                                                "no-gutters": "",
                                                justify: "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "ma-1 px-1 py-0 text-none rounded-lg",
                                                  staticStyle: {
                                                    "font-size": "10px",
                                                  },
                                                  attrs: {
                                                    height: "auto",
                                                    outlined: "",
                                                    color: "#0073D1",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "billedQuarterlyAnnually"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-none",
                                              attrs: {
                                                block: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.submit(
                                                    subscription
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-regular subtitle",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("selectPlan")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    cols: "12",
                                    lg: "9",
                                    md: "8",
                                    xl: "9",
                                    sm: "12",
                                  },
                                },
                                [
                                  subscription.name === "Free"
                                    ? _c(
                                        "v-row",
                                        {
                                          staticClass: "my-6",
                                          class: _vm.$vuetify.breakpoint.mdAndUp
                                            ? ""
                                            : "ml-1",
                                          attrs: {
                                            justify: "center",
                                            "no-gutters": "",
                                          },
                                        },
                                        _vm._l(
                                          subscription.options,
                                          function (item, i) {
                                            return _c(
                                              "v-col",
                                              {
                                                key: i,
                                                staticClass: "pa-0",
                                                class: _vm.$vuetify.breakpoint
                                                  .mdAndUp
                                                  ? ""
                                                  : "ml-8",
                                                attrs: {
                                                  cols: "12",
                                                  lg: i > 5 ? "10" : "5",
                                                  md: "10",
                                                  xl: i > 5 ? "10" : "5",
                                                  sm: "12",
                                                },
                                              },
                                              [
                                                _c("v-icon", {
                                                  attrs: {
                                                    dense: "",
                                                    color: item.extraFeature
                                                      ? "#1E853A"
                                                      : "#E0E0E0",
                                                  },
                                                  domProps: {
                                                    textContent:
                                                      _vm._s("mdi-check"),
                                                  },
                                                }),
                                                _c("span", {
                                                  staticClass:
                                                    "ml-2 text-body-2",
                                                  class:
                                                    item.extraFeature &&
                                                    subscription.name !== "Free"
                                                      ? "font-weight-bold"
                                                      : null,
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.name
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "v-row",
                                        {
                                          staticClass: "my-6",
                                          class: _vm.$vuetify.breakpoint.mdAndUp
                                            ? ""
                                            : "ml-1",
                                          attrs: {
                                            justify: "center",
                                            "no-gutters": "",
                                          },
                                        },
                                        [
                                          subscription.name !== "Free"
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  class: _vm.$vuetify.breakpoint
                                                    .mdAndUp
                                                    ? ""
                                                    : "ml-8",
                                                  attrs: {
                                                    cols: "12",
                                                    lg: "5",
                                                    md: "10",
                                                    xl: "5",
                                                    sm: "12",
                                                  },
                                                },
                                                _vm._l(
                                                  subscription.nonExtraFeatureList,
                                                  function (item, i) {
                                                    return _c(
                                                      "v-row",
                                                      {
                                                        key: i,
                                                        staticClass: "my-1",
                                                        attrs: {
                                                          "no-gutters": "",
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", {
                                                          attrs: {
                                                            dense: "",
                                                            color:
                                                              item.extraFeature
                                                                ? "#1E853A"
                                                                : "#E0E0E0",
                                                          },
                                                          domProps: {
                                                            textContent:
                                                              _vm._s(
                                                                "mdi-check"
                                                              ),
                                                          },
                                                        }),
                                                        _c("span", {
                                                          staticClass:
                                                            "ml-2 text-body-2",
                                                          class:
                                                            item.extraFeature &&
                                                            subscription.name !==
                                                              "Free"
                                                              ? "font-weight-bold"
                                                              : null,
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              item.name
                                                            ),
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                          subscription.name !== "Free"
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  class: _vm.$vuetify.breakpoint
                                                    .mdAndUp
                                                    ? ""
                                                    : "ml-8",
                                                  attrs: {
                                                    cols: "12",
                                                    lg: "5",
                                                    md: "10",
                                                    xl: "5",
                                                    sm: "12",
                                                  },
                                                },
                                                _vm._l(
                                                  subscription.extraFeatureList,
                                                  function (item, i) {
                                                    return _c(
                                                      "v-row",
                                                      {
                                                        key: i,
                                                        staticClass: "my-1",
                                                        attrs: {
                                                          "no-gutters": "",
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", {
                                                          attrs: {
                                                            dense: "",
                                                            color:
                                                              item.extraFeature
                                                                ? "#1E853A"
                                                                : "#E0E0E0",
                                                          },
                                                          domProps: {
                                                            textContent:
                                                              _vm._s(
                                                                "mdi-check"
                                                              ),
                                                          },
                                                        }),
                                                        _c("span", {
                                                          staticClass:
                                                            "ml-2 text-body-2",
                                                          class:
                                                            item.extraFeature &&
                                                            subscription.name !==
                                                              "Free"
                                                              ? "font-weight-bold"
                                                              : null,
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              item.name
                                                            ),
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }